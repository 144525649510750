import React from 'react'
import styled from 'styled-components'

import {
  ContainerMixin,
  PaddingsContainerMixin,
  RowMixin,
  ColMixin,
} from './elements'

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.colorWhite};
  text-align: right;
  padding-top: 35px;
  padding-bottom: 35px;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media (max-width: 575px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

const ContactsContainer = styled.div`
  ${ContainerMixin}
  ${PaddingsContainerMixin}
`

const FooterRow = styled.div`
  ${RowMixin}
`

const FooterCol = styled.div`
  ${ColMixin}
  flex: 0 0 100%;
  max-width: 100%;
`

const FooterPrivacyLink = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colorGreen};
  font-family: ${(props) => props.theme.fontMetaPro};
  font-size: 28px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 24px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 22px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
  }
  @media (max-width: 575px) {
    font-size: 18px;
  }
`

const Footer = () => {
  return (
    <FooterWrapper>
      <ContactsContainer>
        <FooterRow>
          <FooterCol>
            <FooterPrivacyLink
              href="https://www.repxpert.pl/pl/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Polityka prywatności
            </FooterPrivacyLink>
          </FooterCol>
        </FooterRow>
      </ContactsContainer>
    </FooterWrapper>
  )
}

export default Footer
