import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'
import { Link as LinkScroll } from 'react-scroll'
import Sticky from 'react-stickynode'

import { HeaderContainerMixin } from './elements'

import logoImage from '../images/logo3.svg'
import logo2Image from '../images/logo.svg'
import hamburgerImage from '../images/hamburger.svg'

const NavbarNav = styled.nav`
  ${HeaderContainerMixin}
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 200;
`

const NavbarNavLogo = styled.a`
  display: inline-block;
  width: 13%;
  height: 85px;
  background-image: url(${logo2Image});
  ${(props) =>
    props.effects &&
    `
    background-image: url(${logoImage});
  `}
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  @media (min-width: 768px) and (max-width: 991px) {
    width: 180px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 160px;
    height: 75px;
  }
  @media (max-width: 575px) {
    width: 100px;
    height: 60px;
  }
`

const NavbarNavCollapse = styled.div`
  display: flex;
  flex-basis: auto;
  /* flex-grow: 1; */
  align-items: center;
  @media (max-width: 991px) {
    display: ${(props) => (props.open ? 'flex' : 'none')};
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 280px;
    height: 100vh;
    background-color: ${(props) => props.theme.colorGreen};
    padding-left: 30px;
    padding-right: 30px;
    transition: 0.2s ease;
    z-index: 1000;
  }
  @media (max-width: 575px) {
    width: 200px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const NavbarNavList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  @media (max-width: 991px) {
    display: block;
    width: 100%;
  }
`

const NavbarNavListItem = styled.li`
  display: inline-block;
  @media (max-width: 991px) {
    display: block;
  }

  &:last-of-type {
    a {
      @media (max-width: 991px) {
        border-bottom: none;
      }
    }
  }
`

const NavbarNavListItemLink = styled(({ box, ...rest }) => (
  <LinkScroll {...rest} />
))`
  /* color: ${(props) => props.color || props.theme.colorGreen}; */
  color: ${(props) => props.theme.colorGreen};
  display: inline-block;
  font-size: 20px;
  font-family: ${(props) => props.theme.fontLato};
  font-weight: 400;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 15px;
  ${(props) =>
    props.box &&
    `
    color: ${props.theme.colorWhite};
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 991px) {
    display: block;
    color: ${(props) => props.theme.colorWhite};
    text-align: center;
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 30px;
    border-bottom: 2px solid ${(props) => props.theme.colorWhite};
  }
  @media (max-width: 575px) {
    font-size: 18px;
  }
`

const NavbarButton = styled.div`
  position: absolute;
  top: 50%;
  right: 5.26%;
  width: 36px;
  height: 36px;
  background-image: url(${hamburgerImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: -18px;
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 575px) {
    width: 30px;
    height: 30px;
    margin-top: -15px;
  }
`

const NavbarBackgroundToogler = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
`

const Navbar = (props) => {
  const [open, setOpen] = useState(false)

  const setToggleOpen = (open) => {
    const element = document.querySelector('body')
    element.classList.toggle('openMenu')
    setOpen(!open)
  }

  return (
    <Sticky enabled={true}>
      <NavbarNav>
        <NavbarNavLogo href="/" effects={props.visibleEffects} />
        <NavbarButton onClick={() => setToggleOpen(open)} />
        <NavbarNavCollapse open={open}>
          <NavbarNavList>
            {props.page !== 'winners' && (
              <NavbarNavListItemLink
                href="/zwyciezcy"
                as="a"
                onClick={() => {
                  setToggleOpen(open)
                }}
                box={props.isBox}
              >
                zwycięzcy
              </NavbarNavListItemLink>
            )}
            <NavbarNavListItem>
              <NavbarNavListItemLink
                href="#awards"
                as="a"
                onClick={() => {
                  if (props.page === 'winners') {
                    navigate('/#awards')
                    return
                  }
                  setToggleOpen(open)
                  props.changeSlide(4)
                }}
                box={props.isBox}
              >
                nagrody
              </NavbarNavListItemLink>
            </NavbarNavListItem>
            <NavbarNavListItem>
              <NavbarNavListItemLink
                href="regulamin-akcji.pdf"
                target="_blank"
                as="a"
                onClick={() => setToggleOpen(open)}
                box={props.isBox}
              >
                regulamin
              </NavbarNavListItemLink>
            </NavbarNavListItem>
            <NavbarNavListItem>
              <NavbarNavListItemLink
                href="#contacts"
                to="contacts"
                onClick={() => {
                  if (props.page === 'winners') {
                    navigate('/')
                  }
                  setToggleOpen(open)
                  props.changeSlide(5)
                }}
                as="a"
                box={props.isBox}
              >
                kontakt
              </NavbarNavListItemLink>
            </NavbarNavListItem>
          </NavbarNavList>
        </NavbarNavCollapse>
      </NavbarNav>
      {open && <NavbarBackgroundToogler onClick={() => setToggleOpen(open)} />}
    </Sticky>
  )
}

export default Navbar
